import styled from '@emotion/styled'

export const Container = styled.div`
  position: absolute;
  font-size: 4em;
  z-index: 2;
  width: 308px;
  display: flex;
  justify-content: space-between;
  left: 50%;
  transform: translate(-50%, 0);

  i {
    &.green {
      color: green;
      align-self: flex-start;
    }

    &.red {
      color: red;
      transform: rotateY(180deg);
      align-self: flex-end;
    }
  }
`;
